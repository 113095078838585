/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        MobileNavInteractions();
        JobApplication();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var $el = $('#page-carousel');

        if( $el.length ) {
          $el.owlCarousel({
            items: 1,
            autoplay: true,
            autoPlaySpeed: 8000,
            autoPlayTimeout: 8000,
            autoplayHoverPause: true,
            slideSpeed: 1000,
            singleItem:true,
            loop: true,
            smartSpeed: 2000
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {

        jQuery.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBw-oM2iWZegbq8R1RF6QJmxhgNxerOoJg&callback=initMap',
          function(){
            initMap()
        })
      }
    },
    'full_catalog': {
      init: function() {

        jQuery.getScript('/wp-content/themes/silver-line-plastics/dist/scripts/list.js',
          function(){

            var options = {
              valueNames: [ 'name', 'type', 'use' ]
            };

            userList = new List('product-list', options);
          })
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

BootstrapManager = {
  init: function(){

    jQuery('[data-toggle="popover"]').popover()
  }
};ContactFormOverload = {
  init: function(){
    this.checkForUser();
  },
  checkForUser: function(){

    var $form = jQuery('.wpcf7-form');

    if(window.fm_user && $form.length){

      var $name = jQuery('[name="your-name"]', $form)
        ,$email = jQuery('[name="your-email"]', $form)
        ,name = window.fm_user.user_firstname + ' ' + window.fm_user.user_lastname
        ,email = window.fm_user.user_email;

      if(name!='' && name!=' '){
        $name.val(name)
          .data('input_value', name)
          .prop('readonly', true);
      }else{
        $name.prop('readyonly', false);
      }


      if(email!=''){
        $email.val( email )
          .data('input_value', email)
          .prop('readonly', true);
      }else{
        $email.prop('readonly', false);
      }


    }else{

      jQuery('[readonly]',$form).prop('readonly', false);
    }
  }
}


function initMap() {
  var mapDiv = document.getElementById('map');
  var markers = [];
  var contactMap = new google.maps.Map(mapDiv, {
    center: {
      lat: 32.5449925,
      lng: -90.6577423
    },
    zoom: 5,
    disableDefaultUI: true,
    scrollwheel: false,
    navigationControl: false,
    mapTypeControl: false,
    scaleControl: false,
    draggable: false,
  });



  if( typeof slp_locations !== 'undefined' ) {

    jQuery.each(slp_locations, function(index){

      var contentString = '<div id="content">'+ slp_locations[ index ]['address']
        + '<a target="_blank" href="https://maps.google.com?q=' + encodeURIComponent(slp_locations[ index ]['address_raw']) + '">Get Directions</a>'
        + '</div>';

      var infowindow = new google.maps.InfoWindow({
        content: contentString
      });

      var marker = new google.maps.Marker({
        position: {
          lat: +slp_locations[ index ]['lat-long'][0],
          lng: +slp_locations[ index ]['lat-long'][1]
        },
        map: contactMap,
        title: ''
      });

      marker.addListener('click', function() {
        infowindow.open(contactMap, marker);
      });
    });
  }
}


var MobileNavInteractions = function() {

  var $navToggle = jQuery('.navbar-toggle')
    , $firstLevelNavElement = jQuery('.navbar--master ul:not(.sub-menu) > li > a');

  if( $navToggle.is(':visible') ) {

    $firstLevelNavElement.on('click', function(event) {

      var $el = jQuery(this)
        , $parent = $el.closest('li')
        , $subMenu = jQuery('.sub-menu', $parent);

      if( $subMenu.length && ! $el.is('.active') ) {

        event.preventDefault();

        $el.addClass('active');
      }
    })
  }else{
    $firstLevelNavElement.off('click');
  }
}


var JobApplication = function() {

  var $hiddenJobField = jQuery('[name="applying_for"]')
    , $jobInfo = jQuery('.page-title');

  if( $hiddenJobField.length && $jobInfo.length ) {

    var location = jQuery('small', $jobInfo).text()
      , name = jQuery('span', $jobInfo).text();

    $hiddenJobField.val( name + ' | ' + location );
  }
}